// 直販
const CONTRACT_TYPE_DIRECT = 1;
// 先生契約
const CONTRACT_TYPE_TEACHER = 2;
// 契約中
const STATUS_CONTRACTING = 2;
// 契約者：法人
const REPRESENTATIVE_CORPORATION = 1;
// 契約者：団体
const REPRESENTATIVE_SOCIETY = 2;
// 契約者：保護者会
const REPRESENTATIVE_PARENT = 3;

// 可/有
const SOCIETY_CONTRACTS_SELECTED_YES = 1;
// 不可/無し
const SOCIETY_CONTRACTS_SELECTED_NO = 0;

// 責任者DL不可
const NOT_DOWNLOAD_LEADER = 0;
// 責任者DL可
const DOWNLOAD_LEADER_YES = 1;
// 個別配送
const DELIVERY_TYPE_INDIVIDUAL = 0;
// 一括配送
const DELIVERY_TYPE_BLUKDELIVERY = 1;
// 一括配送住所＝その他
const BULKDELIVERY_ADDRESS_TYPE_OTHER = 1;
// 配送先住所の指定なし
const IS_REQUIRED_DELIVERY_ADDRESS_NONE = 0;
// 配送先住所の指定あり
const IS_REQUIRED_DELIVERY_ADDRESS_YES = 1;
// 事前確認不要
const NOT_REQUIRED_PRE_CONFIRM = 0;
// 事前確認方法：ネット
const PRE_CONFIRMATION_TYPE_NET = 0;
// 掲載日固定する
const IS_ENABLED_PUBLISHING_PERIODS_YES = 1;
// 写真の並び順の初期値：名前（写真番号）順
const PHOTO_ALIGNMENT_PHOTOGRAPHNO = 0;
// 写真の並び順の初期値：撮影日順
const PHOTO_ALIGNMENT_DATETIMEORIGINAL = 1;
// 認証タイプ：年度
const AUTHENTICATION_TYPE_YEAR = 0;
// 不定期キャンペーン：不可
const CAMPAIGN_TYPE_NO = 0;
// 不定期キャンペーン：随時判断
const CAMPAIGN_TYPE_OCCASION = 2;
// 集合写真の専用フレームタイプ帯2L
const FRAME_FOR_GROUP_PHOTO_TYPE_LINE_2L = 0;
// 招待メール未送信
const INVITATION_MAIL_NOT_SENT = 0;
// 招待メール送信済み
const INVITATION_MAIL_SENT = 1;
// 責任者登録済み
const INVITATION_MAIL_DONE = 2;
// plan 商品種類
const PRODUCT_TYPE_PHOTO = 1;
// 物販のプラン
const GOODS_PLAN_IDS = [
  2, // 物販（直販）
];
// JVIS
const PRINTING_TYPE_JVIS = 2;
// 北菱
const PRINTING_TYPE_HOKURYO = 0;
// チラシ事前発送種類 なし
const FLYER_PRE_DELIVERY_TYPE_NONE = 0;
// チラシ事前発送種類 公開前
const FLYER_PRE_DELIVERY_TYPE_BEFORE_OPEN = 2;
// 期間外販売無し
const IS_ENABLED_OUT_OF_PERIOD_SALES_NONE = 0;
// 撮影情報登録がweb
const PHOTOGRAPHY_SUBMIT_TYPE_WEB = 1;

export type TSocietyContractType = 'direct' | 'teacher';
const getSocietyContractTypeIdByType = (
  societyContractType: TSocietyContractType
): number =>
  societyContractType === 'direct'
    ? CONTRACT_TYPE_DIRECT
    : CONTRACT_TYPE_TEACHER;

const isContractTypeDirect = (contractType: number): boolean =>
  contractType === CONTRACT_TYPE_DIRECT;
const isContractTypeTeacher = (contractType: number): boolean =>
  contractType === CONTRACT_TYPE_TEACHER;

const isContracting = (status: number): boolean =>
  status === STATUS_CONTRACTING;

const isProductTypePhoto = (productType: number): boolean =>
  productType === PRODUCT_TYPE_PHOTO;

/**
 * 物販のプランか？
 * @duplicated フォーム画面用の苦肉の策なので、基本的にisProductTypePhotoを使ってください
 */
const isGoodsPlan = (planId: number): boolean =>
  GOODS_PLAN_IDS.includes(planId);

export {
  getSocietyContractTypeIdByType,
  isContractTypeDirect,
  isContractTypeTeacher,
  isContracting,
  isProductTypePhoto,
  isGoodsPlan,
  CONTRACT_TYPE_DIRECT,
  CONTRACT_TYPE_TEACHER,
  STATUS_CONTRACTING,
  REPRESENTATIVE_SOCIETY,
  REPRESENTATIVE_CORPORATION,
  REPRESENTATIVE_PARENT,
  SOCIETY_CONTRACTS_SELECTED_YES,
  SOCIETY_CONTRACTS_SELECTED_NO,
  NOT_DOWNLOAD_LEADER,
  DOWNLOAD_LEADER_YES,
  DELIVERY_TYPE_INDIVIDUAL,
  DELIVERY_TYPE_BLUKDELIVERY,
  BULKDELIVERY_ADDRESS_TYPE_OTHER,
  IS_REQUIRED_DELIVERY_ADDRESS_NONE,
  IS_REQUIRED_DELIVERY_ADDRESS_YES,
  NOT_REQUIRED_PRE_CONFIRM,
  PRE_CONFIRMATION_TYPE_NET,
  IS_ENABLED_PUBLISHING_PERIODS_YES,
  PHOTO_ALIGNMENT_PHOTOGRAPHNO,
  PHOTO_ALIGNMENT_DATETIMEORIGINAL,
  AUTHENTICATION_TYPE_YEAR,
  CAMPAIGN_TYPE_NO,
  CAMPAIGN_TYPE_OCCASION,
  FRAME_FOR_GROUP_PHOTO_TYPE_LINE_2L,
  INVITATION_MAIL_NOT_SENT,
  INVITATION_MAIL_SENT,
  INVITATION_MAIL_DONE,
  PRINTING_TYPE_JVIS,
  PRINTING_TYPE_HOKURYO,
  FLYER_PRE_DELIVERY_TYPE_NONE,
  FLYER_PRE_DELIVERY_TYPE_BEFORE_OPEN,
  IS_ENABLED_OUT_OF_PERIOD_SALES_NONE,
  PHOTOGRAPHY_SUBMIT_TYPE_WEB,
};
