import { MouseEventHandler, useState, useRef } from 'react';
import { TInvitationResponse } from './types';
import { Link } from 'react-router-dom';
import {
  TOnlyValidationRuleResponse,
  TValidatorResponse,
} from '@/components/shared/Form/types';
import { fetcher } from '@/ts/fetch';
import ErrorMessages from '@/components/shared/ErrorMessages';
import { ApiErrors } from '@/components/shared/Form/Errors';

import StatusLabel from '../../../shared/StatusLabel/App';
// 約款同意削除
// import { dateFormat, dateTimeFormat, HYPHEN } from "../../../../ts/formatTools";
import { dateFormat, HYPHEN } from '../../../../ts/formatTools';
import {
  STATUS_CONTRACTING,
  INVITATION_MAIL_NOT_SENT,
  INVITATION_MAIL_SENT,
  INVITATION_MAIL_DONE,
  BULKDELIVERY_ADDRESS_TYPE_OTHER,
  DELIVERY_TYPE_BLUKDELIVERY,
  isContracting,
  isContractTypeDirect,
  isContractTypeTeacher,
} from '../../../../ts/societyContractConstants';
import { toMessages } from '../../../../ts/useApi';
import { errorToast, successToast } from '../../../../ts/toast';
import { kanriUrl, ownerUrl } from '../../../../ts/url';
import { TDefaultPricePattern, TSocietyContractContainer } from '../types';
import SalesManagement from './SalesManagement';
import SalesManagementPlan from './SalesManagementPlan';
import PriceTable from '../../../shared/PriceTable/App';
import {
  getEventRequestsCreateBySocietyContractPlanUrl,
  getEventRequestsCreateManyBySocietyContractPlanUrl,
} from '../../../../ts/legacyUrl';
import OwneracountList from './OwneraccountList';
import { alertApiError } from '../../../../ts/formValidation';
import Linkify from 'react-linkify';
import { fetchPdf } from '@/ts/fetch';
import { isGoodsPlan } from '../../../../ts/societyContractConstants';

const EventSchedulePdfUrl: React.FC<{ url: string | null }> = ({ url }) => {
  if (!url) {
    return <p>未作成</p>;
  } else {
    return <a href={url}>作成済み</a>;
  }
};

const DownloadFlyer: React.FC<{
  id: number;
  keyname: string;
  setErrorMessages: React.Dispatch<React.SetStateAction<string[]>>;
  downloadLinkRef: React.MutableRefObject<HTMLAnchorElement | null>;
  organizationName: string;
}> = ({ id, keyname, setErrorMessages, downloadLinkRef, organizationName }) => {
  const downloadFlyer = async () => {
    try {
      const pdfResponse = await fetchPdf<{ validator: TValidatorResponse }>(
        `/api/certificationkeys/${id}/flyer`
      );
      if (!(pdfResponse instanceof Blob)) {
        setErrorMessages([
          'チラシのダウンロードに失敗しました。再ダウンロードを試しても失敗する場合は、開発者にご連絡をください',
        ]);
        errorToast('エラーが発生しました');
        return;
      }
      setErrorMessages([]);
      const link = downloadLinkRef.current!;
      const url = URL.createObjectURL(pdfResponse);
      link.href = url;
      link.download = `${organizationName}${keyname}写真閲覧キーチラシ.pdf`;
      link.click();
    } catch (e) {
      setErrorMessages(toMessages(e));
      errorToast('エラーが発生しました');
    }
  };
  return (
    <>
      <div className="u-mgr_s u-mgb_s">
        <button
          type="button"
          className="c-btn_rectangle c-btn_manage u-mgr_m"
          onClick={downloadFlyer}
        >
          <i className="c-icon c-icon__xxsmall c-icon_download u-mgr_xs" />
          <span>{keyname}写真閲覧キーチラシ</span>
        </button>
      </div>
    </>
  );
};
const SocietyContractContainer: React.FC<{
  index: number;
  societyContractContainer: TSocietyContractContainer;
  organizationName: string;
  mutate: (f?: (d: undefined) => undefined) => void;
}> = ({ index, societyContractContainer, organizationName, mutate }) => {
  const {
    societyContract,
    societyDirectContract,
    bulkdeliveryInfo,
    societyContractPlans,
    // 約款同意削除
    // societyContractUseraccountAcceptedHistories,
    certificationkeysUsedInFlyer,
    salesManagement,
    salesManagementPlans,
    bankAccount,
    owneraccounts,
    canCreateOwneraccount,
  } = societyContractContainer;
  const emptyValidator = {
    messages: {},
    hasError: false,
    rules: {},
  };
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [validator, setValidator] = useState<
    TValidatorResponse | TOnlyValidationRuleResponse
  >(emptyValidator);
  const sendInvitationMail: MouseEventHandler = async (event) => {
    if (
      !window.confirm(
        '招待メールを送信します。よろしいですか？（一般ユーザーが存在する場合格上げします）'
      )
    ) {
      return;
    }
    try {
      const response = (await fetcher(
        `/api/invitation_mail/${societyContract.id}/send`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )) as TInvitationResponse;
      setErrorMessages([]);
      if (response.validator.hasError) {
        setValidator(response.validator);
        alertApiError('送信に失敗しました');
        return;
      }
      successToast('送信しました');
      mutate();
    } catch (e) {
      setErrorMessages(toMessages(e));
      errorToast('送信に失敗しました');
    }
  };
  const removeLeaderAccount: MouseEventHandler = async (event) => {
    if (!window.confirm('責任者招待を取り消します。よろしいですか？')) {
      return;
    }
    try {
      const response = (await fetcher(
        `/api/remove_leader_account/${societyContract.id}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )) as TInvitationResponse;
      setErrorMessages([]);
      if (response.validator.hasError) {
        setValidator(response.validator);
        return;
      }
      successToast('招待を取り消しました。');
      mutate();
    } catch (e) {
      setErrorMessages(toMessages(e));
      errorToast('取り消しに失敗しました');
    }
  };
  const removeLeaderAccountKey: MouseEventHandler = async (event) => {
    if (!window.confirm('責任者を格下げします。よろしいですか？')) {
      return;
    }
    try {
      const response = (await fetcher(
        `/api/downgrade_leader_account/${societyContract.id}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )) as TInvitationResponse;
      setErrorMessages([]);
      if (response.validator.hasError) {
        setValidator(response.validator);
        alertApiError('格下げに失敗しました');
        return;
      }
      successToast('格下げしました');
      mutate();
    } catch (e) {
      setErrorMessages(toMessages(e));
      errorToast('エラーが発生しました');
    }
  };
  const downloadLinkRef = useRef<HTMLAnchorElement>(null);
  return (
    <div
      className={`${
        index > 0
          ? 'p-groupsSummary_societyContracts'
          : 'p-groupsSummary_contract'
      }`}
    >
      <ErrorMessages messages={errorMessages} />
      <ApiErrors {...validator} />
      <div className="c-section_subTitle_gry l-flex_between_center u-mgb_m u-fz_default">
        <div className="l-flex_between_center p-groupsSummary_outline">
          <div>
            <b className="u-mgr_s">NO.{index + 1}</b>
            <StatusLabel
              isPositive={isContracting(societyContract.status)}
              statusName={societyContract.statusName}
              additionalClassName="u-fz_xs"
            />
            <span className="c-statusLabel c-statusLabel__monotone u-fz_xs">
              契約ID: {societyContract.id}
            </span>
          </div>
          <div>
            <span className="u-mgr_s">
              契約種類：
              {societyContract.societyContractTypeName}
            </span>
            {/* <span className="u-mgr_s">プラン：</span> */}
            {/* <span>契約者名：{societyContract.name}</span> */}
          </div>
          &nbsp;
          {/* 契約プラン */}
          <div>
            {societyContractPlans.map((contractPlan, index) => (
              <span key={'plan_' + (index + 1)}>
                <a href={'#p-groupsSummary_plan_' + (index + 1)}>
                  <span className="u-mgr_s">
                    契約プラン{index + 1}：{contractPlan.planName}
                  </span>
                </a>
                &nbsp;
              </span>
            ))}
          </div>
          {/* 商品配送方法 */}
          <div>
            <span className="u-mgr_s">
              商品配送方法：
              {societyContract.deliveryTypeName}
            </span>
          </div>
        </div>
        <div className="l-flex_between_center p-groupsSummary_upper">
          <div className="u-mgr_s">
            <span className="u-fz_xs c-statusLabel c-statusLabel__monotone">
              登録日
            </span>
            <span className="u-fz_xs">
              {dateFormat(societyContract.createdday)}
            </span>
          </div>
          <div className="u-mgr_s">
            <span className="u-fz_xs c-statusLabel c-statusLabel__monotone">
              更新日
            </span>
            <span className="u-fz_xs">
              {dateFormat(societyContract.updatedday)}
            </span>
          </div>
          <div className="l-flex_end">
            <Link
              className="c-btn_rectangle c-btn_edit"
              to={`/society_contracts/${societyContract.id}/edit`}
            >
              <i className="c-icon c-icon__xsmall c-icon_edit" />
            </Link>
          </div>
        </div>
      </div>
      <ul className="l-col_wrap l-flex l-col_wrap__isSpMode l-col_wrap__noBorder">
        <li className="l-col_12">
          <ul className="l-flex">
            <a
              href={kanriUrl({
                action_owner_NEWSlist: 'true',
                newsflag: '0',
                societyContractId: societyContract.id.toString(),
              })}
              className="c-textlink"
            >
              お知らせ文
            </a>
          </ul>
        </li>
      </ul>
      <div className="l-flex_between_center u-fz_default">
        <div className="l-flex_between_center p-groupsSummary_outline">
          {!(
            isContractTypeTeacher(societyContract.societyContractTypeId) ||
            (societyContractPlans.length === 1 &&
              isGoodsPlan(societyContractPlans[0].planId))
          ) &&
            certificationkeysUsedInFlyer!.map((certificationkey, index) => (
              <DownloadFlyer
                id={certificationkey.id}
                keyname={certificationkey.keyname}
                setErrorMessages={setErrorMessages}
                downloadLinkRef={downloadLinkRef}
                organizationName={organizationName}
                key={index}
              />
            ))}
          <a href="/" className="is-hidden" ref={downloadLinkRef}>
            dummy
          </a>
        </div>
      </div>
      <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
        <li className="l-col_12">
          <ul className="l-flex">
            <li className="c-dataLabel">契約種類</li>
            <li className="c-dataValue">
              <span>{societyContract.societyContractTypeName}</span>
            </li>
          </ul>
        </li>
        <li className="l-col_12">
          <ul className="l-flex">
            <li className="c-dataLabel">契約締結日</li>
            <li className="c-dataValue">
              <span>{dateFormat(societyContract.concludeDate)}</span>
            </li>
          </ul>
        </li>
      </ul>
      {isContractTypeTeacher(societyContract.societyContractTypeId) && (
        <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
          <li className="l-col_24">
            <ul className="l-flex">
              <li className="c-dataLabel">口座</li>
              <li className="c-dataValue">
                {bankAccount ? (
                  <Link
                    className="c-textlink"
                    to={
                      '/society_contracts/' +
                      societyContract.id +
                      '/bank_accounts'
                    }
                  >
                    口座情報
                  </Link>
                ) : (
                  <Link
                    className="c-textlink"
                    to={`/society_contracts/${societyContract.id}/bank_accounts/create`}
                  >
                    口座情報を登録
                  </Link>
                )}
              </li>
            </ul>
          </li>
        </ul>
      )}
      <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
        <li className="l-col_12">
          <ul className="l-flex">
            <li className="c-dataLabel p-groupsSummary_dataLabel_l">
              責任者メールアドレス
            </li>
            <li className="c-dataValue">
              {societyContract.useraccountno ? (
                <a href={ownerUrl('users/' + societyContract.useraccountno)}>
                  {societyContract.useraccountMailaddress}
                </a>
              ) : (
                societyContract.useraccountMailaddress
              )}
              <br />
              {societyContract.invitationStatus !== INVITATION_MAIL_DONE &&
              societyContract.status === STATUS_CONTRACTING &&
              societyContract.mailaddress ? (
                <button onClick={sendInvitationMail}>招待</button>
              ) : (
                ''
              )}
              {societyContract.invitationStatus === INVITATION_MAIL_SENT &&
              societyContract.useraccountno ? (
                <button onClick={removeLeaderAccount}>招待取り消し</button>
              ) : (
                ''
              )}
              {societyContract.invitationStatus === INVITATION_MAIL_DONE &&
              societyContract.useraccountno ? (
                <button onClick={removeLeaderAccountKey}>格下げ</button>
              ) : (
                ''
              )}
            </li>
            {societyContract.invitationStatus === INVITATION_MAIL_NOT_SENT ? (
              ''
            ) : (
              <li className="c-dataValue">
                <a
                  href={kanriUrl({
                    action_owner_SYSTEMmaillog: 'true',
                    mailaddress: societyContract.useraccountMailaddress,
                  })}
                >
                  メールログ
                </a>
              </li>
            )}
          </ul>
        </li>
        <li className="l-col_12">
          <ul className="l-flex">
            <li className="c-dataLabel">責任者招待</li>
            <li className="c-dataValue">
              <span>{societyContract.invitationStatusName}</span>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
        <li className="l-col_12">
          <ul className="l-flex">
            <li className="c-dataLabel p-groupsSummary_dataLabel_l">
              システム管理者メールアドレス
            </li>
            <li className="c-dataValue">
              <span>{societyContract.mailaddress}</span>
            </li>
          </ul>
        </li>
        <li className="l-col_12">
          <ul className="l-flex">
            <li className="c-dataLabel">
              問合せ受付用
              <br />
              メールアドレス
            </li>
            <li className="c-dataValue">
              <span>{societyContract.inquiryMailaddress}</span>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
        <li className="l-col_12">
          <ul className="l-flex">
            <li className="c-dataLabel">契約者種類</li>
            <li className="c-dataValue">
              <span>{societyContract.representativeTypeName}</span>
            </li>
          </ul>
        </li>
        <li className="l-col_12">
          <ul className="l-flex">
            <li className="c-dataLabel">責任者ダウンロードの可/不可</li>
            <li className="c-dataValue">
              <span>{societyContract.isEnabledDownloadByLeaderName}</span>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
        <li className="l-col_24">
          <ul className="l-flex">
            <li className="c-dataLabel">責任者（契約者）</li>
            <li className="c-dataValue">
              <div className="t-bgBox_gray">
                <ul className="l-col_wrap l-flex l-col_wrap__isSpMode l-col_wrap__noBorder">
                  <li className="l-col_12">
                    <ul className="l-flex">
                      <li className="c-dataLabel">氏名</li>
                      <li className="c-dataValue">
                        {societyContract.representativeName}
                        <span className="u-mgl_m">
                          {societyContract.representativeNamekana &&
                            `(${societyContract.representativeNamekana})`}
                        </span>
                      </li>
                    </ul>
                  </li>
                  <li className="l-col_12">
                    <ul className="l-flex">
                      <li className="c-dataLabel">役職</li>
                      <li className="c-dataValue">
                        {societyContract.representativePosition}
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                  <li className="l-col_12">
                    <ul className="l-flex">
                      <li className="c-dataLabel">電話番号</li>
                      <li className="c-dataValue">
                        {societyContract.representativeTelephonenumber}
                      </li>
                    </ul>
                  </li>
                  <li className="l-col_12">
                    <ul className="l-flex">
                      <li className="c-dataLabel">FAX番号</li>
                      <li className="c-dataValue">
                        {societyContract.representativeFaxnumber}
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                  <li className="l-col_12">
                    <ul className="l-flex">
                      <li className="c-dataLabel">
                        メール
                        <br />
                        アドレス
                      </li>
                      <li className="c-dataValue">
                        {societyContract.representativeMailaddress}
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
        <li className="l-col_24">
          <ul className="l-flex">
            <li className="c-dataLabel">先方担当者</li>
            <li className="c-dataValue">
              <div className="t-bgBox_gray">
                <ul className="l-col_wrap l-flex l-col_wrap__isSpMode l-col_wrap__noBorder">
                  <li className="l-col_12">
                    <ul className="l-flex">
                      <li className="c-dataLabel">氏名</li>
                      <li className="c-dataValue">
                        {societyContract.contactName} <br />
                        <span className="u-fz_xs">
                          {societyContract.contactNamekana}
                        </span>
                      </li>
                    </ul>
                  </li>
                  <li className="l-col_12">
                    <ul className="l-flex">
                      <li className="c-dataLabel">役職</li>
                      <li className="c-dataValue">
                        {societyContract.contactPosition}
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                  <li className="l-col_12">
                    <ul className="l-flex">
                      <li className="c-dataLabel">電話番号</li>
                      <li className="c-dataValue">
                        {societyContract.contactTelephonenumber}
                      </li>
                    </ul>
                  </li>
                  <li className="l-col_12">
                    <ul className="l-flex">
                      <li className="c-dataLabel">FAX番号</li>
                      <li className="c-dataValue">
                        {societyContract.contactFaxnumber}
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                  <li className="l-col_12">
                    <ul className="l-flex">
                      <li className="c-dataLabel">
                        メール
                        <br />
                        アドレス
                      </li>
                      <li className="c-dataValue">
                        {societyContract.contactMailaddress}
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
        <li className="l-col_12">
          <ul className="l-flex">
            <li className="c-dataLabel">配送先住所指定</li>
            <li className="c-dataValue">
              {societyContract.isRequiredDeliveryAddressName}
              {!!societyContract.isRequiredDeliveryAddress && (
                <div className="t-bgBox_gray u-mgt_xs">
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="c-dataLabel">住所</li>
                    <li className="c-dataValue">
                      <div className="u-pre_wrap">
                        〒{societyContract.deliveryPostcode}{' '}
                        {societyContract.deliveryPrefectureName}{' '}
                        {societyContract.deliveryCity}{' '}
                        {societyContract.deliveryAddress}
                      </div>
                    </li>
                  </ul>
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_12">
                      <ul className="l-flex">
                        <li className="c-dataLabel">宛名</li>
                        <li className="c-dataValue">
                          <span>{societyContract.deliveryAddressee}</span>
                        </li>
                      </ul>
                    </li>
                    <li className="l-col_12">
                      <ul className="l-flex">
                        <li className="c-dataLabel">電話番号</li>
                        <li className="c-dataValue">
                          <span>{societyContract.deliveryTelephonenumber}</span>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              )}
            </li>
          </ul>
        </li>
      </ul>
      <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
        <li className="l-col_12">
          <ul className="l-flex">
            <li className="c-dataLabel p-groupsSummary_dataLabel_m">
              UP作業時の注意点
            </li>
            <li className="c-dataValue">
              <div className="u-pre_wrap">
                <Linkify>{societyContract.uploadingPrecaution}</Linkify>
              </div>
            </li>
          </ul>
        </li>
        <li className="l-col_12">
          <ul className="l-flex">
            <li className="c-dataLabel p-groupsSummary_dataLabel_m">
              撮影時の注意点
            </li>
            <li className="c-dataValue">
              <div className="u-pre_wrap">
                <Linkify>{societyContract.photographingPrecaution}</Linkify>
              </div>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
        <li className="l-col_12">
          <ul className="l-flex">
            <li className="c-dataLabel">事前確認</li>
            <li className="c-dataValue">
              {societyContract.isRequiredPreConfirmationName}
              {!!societyContract.isRequiredPreConfirmation &&
                ` （${societyContract.preConfirmationTypeName}）`}
            </li>
          </ul>
        </li>
      </ul>
      <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
        <li className="l-col_24">
          <ul className="l-flex">
            <li className="c-dataLabel">チラシの有無</li>
            <li className="c-dataValue">
              {societyContract.isRequiredFlyerName}
              {!!societyContract.isRequiredFlyer && (
                <div className="t-bgBox_gray u-mgt_xs">
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode l-col_wrap__noBorder">
                    <li className="l-col_12">
                      <ul className="l-flex">
                        <li className="c-dataLabel p-groupsSummary_dataLabel_m">
                          チラシ発送指定日の有無
                        </li>
                        <li className="c-dataValue">
                          <span>
                            {societyContract.isEnabledFlyerPreDeliveryName}
                            {' / '}
                            {societyContract.flyerPreDeliveryTypeName}
                            {' / '}
                            {societyContract.numberOfBusinessDaysToPreship}
                            日前発送
                          </span>
                        </li>
                      </ul>
                    </li>
                    <li className="l-col_12">
                      <ul className="l-flex">
                        <li className="c-dataLabel">チラシ部数</li>
                        <li className="c-dataValue">
                          <span>{societyContract.numberOfFlyers}</span>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="c-dataLabel">チラシの文言</li>
                    <li className="c-dataValue">
                      <div className="u-pre_wrap">
                        {societyContract.contentsOfFlyer}
                      </div>
                    </li>
                  </ul>
                </div>
              )}
            </li>
          </ul>
        </li>
      </ul>
      <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
        <li className="l-col_12">
          <ul className="l-flex">
            <li className="c-dataLabel p-groupsSummary_dataLabel_m">
              サンプルブック作成時の注意点
            </li>
            <li className="c-dataValue">
              <div className="u-pre_wrap">
                <Linkify>{societyContract.samplebookPrecaution}</Linkify>
              </div>
            </li>
          </ul>
        </li>
        <li className="l-col_12">
          <ul className="l-flex">
            <li className="c-dataLabel p-groupsSummary_dataLabel_m">
              チラシ・SB等配送の注意点
            </li>
            <li className="c-dataValue">
              <div className="u-pre_wrap">
                <Linkify>{societyContract.deliveryPrecaution}</Linkify>
              </div>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
        <li className="l-col_12">
          <ul className="l-flex">
            <li className="c-dataLabel p-groupsSummary_dataLabel_m">
              撮影時備考
            </li>
            <li className="c-dataValue">
              <span className="u-pre_wrap">
                <Linkify>{societyContract.photographingMemo}</Linkify>
              </span>
            </li>
          </ul>
        </li>
        <li className="l-col_12">
          <ul className="l-flex">
            <li className="c-dataLabel p-groupsSummary_dataLabel_m">
              その他の注意点
            </li>
            <li className="c-dataValue">
              <div className="u-pre_wrap">
                <Linkify>{societyContract.otherPrecaution}</Linkify>
              </div>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
        <li className="l-col_12">
          <ul className="l-flex">
            <li className="c-dataLabel p-groupsSummary_dataLabel_m">
              掲載日固定
            </li>
            <li className="c-dataValue">
              {societyContract.isEnabledPublishingPeriodsName}
              {!!societyContract.isEnabledPublishingPeriods && (
                <div className="t-bgBox_gray u-mgt_xs">
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode l-col_wrap__noBorder">
                    <li className="l-col_12">
                      <ul className="l-flex">
                        <li className="c-dataLabel">掲載開始日・終了日</li>
                        <li className="c-dataValue">
                          <span>
                            {societyContract.publishingStartDay}日{' - '}
                            {societyContract.publishingEndDay}日
                          </span>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              )}
            </li>
          </ul>
        </li>
      </ul>
      <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
        <li className="l-col_12">
          <ul className="l-flex">
            <li className="c-dataLabel p-groupsSummary_dataLabel_m">
              写真の並び順の初期値
            </li>
            <li className="c-dataValue">
              <span>{societyContract.photoAlignmentName}</span>
            </li>
          </ul>
        </li>
        <li className="l-col_12">
          <ul className="l-flex">
            <li className="c-dataLabel p-groupsSummary_dataLabel_m">
              認証タイプ
            </li>
            <li className="c-dataValue">
              <span>{societyContract.authenticationTypeName}</span>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
        <li className="l-col_12">
          <ul className="l-flex">
            <li className="c-dataLabel p-groupsSummary_dataLabel_m">
              スタッフ撮影
            </li>
            <li className="c-dataValue">
              <span>{societyContract.isEnabledStaffPhotographingName}</span>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
        <li className="l-col_12">
          <ul className="l-flex">
            <li className="c-dataLabel p-groupsSummary_dataLabel_m">
              サンプルブックの有無
            </li>
            <li className="c-dataValue">
              <span>{societyContract.isRequiredSamplebookName}</span>
            </li>
          </ul>
        </li>
        <li className="l-col_12">
          <ul className="l-flex">
            <li className="c-dataLabel p-groupsSummary_dataLabel_m">
              アルバム購入
            </li>
            <li className="c-dataValue">
              <span>{societyContract.isEnabledAlbumSalesName}</span>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
        <li className="l-col_12">
          <ul className="l-flex">
            <li className="c-dataLabel p-groupsSummary_dataLabel_m">
              撮影者名の表示
            </li>
            <li className="c-dataValue">
              <span>{societyContract.isEnabledDisplayPartnerNameName}</span>
            </li>
          </ul>
        </li>
        <li className="l-col_12">
          <ul className="l-flex">
            <li className="c-dataLabel p-groupsSummary_dataLabel_m">
              FAX申込書部数
            </li>
            <li className="c-dataValue">
              <span>{societyContract.numberOfFaxApplicationForms}</span>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
        <li className="l-col_12">
          <ul className="l-flex">
            <li className="c-dataLabel p-groupsSummary_dataLabel_m">
              個人情報の表示
            </li>
            <li className="c-dataValue">
              <span>
                {
                  societyContract.isEnabledDisplayPersonalInformationToPartnerName
                }
              </span>
            </li>
          </ul>
        </li>
        <li className="l-col_12">
          <ul className="l-flex">
            <li className="c-dataLabel p-groupsSummary_dataLabel_xxl">
              顔検索機能使用可否フラグ イベント作成時デフォルト値
            </li>
            <li className="c-dataValue">
              <span>{societyContract.isEnabledFaceRecommendName}</span>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
        <li className="l-col_12">
          <ul className="l-flex">
            <li className="c-dataLabel p-groupsSummary_dataLabel_m">
              プチパネル販売可否
            </li>
            <li className="c-dataValue">
              <div className="u-pre_wrap">
                {societyContract.isSalablePhotopanelName}
              </div>
            </li>
          </ul>
        </li>
        <li className="l-col_12">
          <ul className="l-flex">
            <li className="c-dataLabel p-groupsSummary_dataLabel_m">
              FAX手数料
            </li>
            <li className="c-dataValue">
              <div className="u-pre_wrap">
                {societyContract.isEnabledFaxCommissionName}
              </div>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
        <li className="l-col_12">
          <ul className="l-flex">
            <li className="c-dataLabel p-groupsSummary_dataLabel_m">
              まとめ発送の利用可否
            </li>
            <li className="c-dataValue">
              <div className="u-pre_wrap">
                {societyContract.isEnabledCombinedShipmentName}
              </div>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
        <li className="l-col_12">
          <ul className="l-flex">
            <li className="c-dataLabel p-groupsSummary_dataLabel_m">
              不定期キャンペーン可/不可
            </li>
            <li className="c-dataValue">
              <div className="u-pre_wrap">
                {societyContract.campaignTypeName}
              </div>
            </li>
          </ul>
        </li>
        <li className="l-col_12">
          <ul className="l-flex">
            <li className="c-dataLabel p-groupsSummary_dataLabel_l">
              モデル募集フォームの表示/非表示
            </li>
            <li className="c-dataValue">
              <div className="u-pre_wrap">
                {societyContract.isEnabledToDisplayModelRecruitmentFormName}
              </div>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
        <li className="l-col_12">
          <ul className="l-flex">
            <li className="c-dataLabel p-groupsSummary_dataLabel_m">
              0円価格登録の可/不可
            </li>
            <li className="c-dataValue">
              <div className="u-pre_wrap">
                {societyContract.isEnabledFreePhotoName}
              </div>
            </li>
          </ul>
        </li>
        <li className="l-col_12">
          <ul className="l-flex">
            <li className="c-dataLabel p-groupsSummary_dataLabel_m">
              責任者約款表示
            </li>
            <li className="c-dataValue">
              <div className="u-pre_wrap">
                {societyContract.isEnabledDisplayAgreementForLeaderName}
              </div>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
        <li className="l-col_12">
          <ul className="l-flex">
            <li className="c-dataLabel p-groupsSummary_dataLabel_m">
              (廃止)イベントの自動作成
            </li>
            <li className="c-dataValue">
              <span>{societyContract.isEnabledAutoEventCreateName}</span>
            </li>
          </ul>
        </li>
        <li className="l-col_12">
          <ul className="l-flex">
            <li className="c-dataLabel p-groupsSummary_dataLabel_m">
              集合写真の専用フレーム
            </li>
            <li className="c-dataValue">
              <div className="u-pre_wrap">
                {societyContract.isEnabledFrameForGroupPhotoName}
                {!!societyContract.isEnabledFrameForGroupPhoto &&
                  ` （${societyContract.frameForGroupPhotoTypeName}）`}
              </div>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="l-col_wrap l-flex c-label_line l-col_wrap__isSpMode">
        <li className="l-col_12">
          <ul className="l-flex">
            <li className="c-dataLabel p-groupsSummary_dataLabel_m">
              商品配送方法
            </li>
            <li className="c-dataValue">
              <span>{societyContract.deliveryTypeName}</span>
            </li>
          </ul>
        </li>
        <li className="l-col_12">
          <ul className="l-flex">
            <li className="c-dataLabel p-groupsSummary_dataLabel_m">
              送料の有無
            </li>
            <li className="c-dataValue">
              <div className="u-pre_wrap">
                {societyContract.isEnabledPostagePaidForIndividualdeliveryName}
              </div>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="l-col_wrap l-flex c-label_line l-col_wrap__isSpMode">
        <li className="l-col_12">
          <ul className="l-flex">
            <li className="c-dataLabel p-groupsSummary_dataLabel_l">
              一括配送時の宛名の不要/必要
            </li>
            <li className="c-dataValue">
              <span>
                {societyContract.isRequiredAddressesForBulkdeliveryName}
              </span>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="l-col_wrap l-flex c-label_line l-col_wrap__isSpMode">
        <li className="l-col_12">
          <ul className="l-flex">
            <li className="c-dataLabel p-groupsSummary_dataLabel_m">
              一括配送手数料
            </li>
            <li className="c-dataValue">
              <span>
                {societyContract.deliveryType === DELIVERY_TYPE_BLUKDELIVERY
                  ? bulkdeliveryInfo?.carriage
                  : HYPHEN}
              </span>
            </li>
          </ul>
        </li>
        <li className="l-col_12">
          <ul className="l-flex">
            <li className="c-dataLabel p-groupsSummary_dataLabel_m">
              一括配送締日
            </li>
            <li className="c-dataValue">
              <span>
                {societyContract.deliveryType === DELIVERY_TYPE_BLUKDELIVERY
                  ? bulkdeliveryInfo?.closeDay
                  : HYPHEN}
              </span>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="l-col_wrap l-flex c-label_line l-col_wrap__isSpMode">
        <li className="l-col_24">
          <ul className="l-flex">
            <li className="c-dataLabel p-groupsSummary_dataLabel_m">
              一括配送宛先
            </li>
            <li className="c-dataValue">
              <span>
                {societyContract.deliveryType === DELIVERY_TYPE_BLUKDELIVERY
                  ? bulkdeliveryInfo?.deliveryAddressTypeName
                  : HYPHEN}
              </span>
              {bulkdeliveryInfo?.deliveryAddressType ===
                BULKDELIVERY_ADDRESS_TYPE_OTHER && (
                <div className="t-bgBox_gray u-mgt_xs">
                  <ul className="l-col_wrap l-flex c-label_line l-col_wrap__isSpMode">
                    <li className="c-dataLabel">住所</li>
                    <li className="c-dataValue">
                      <div className="u-pre_wrap">
                        〒{bulkdeliveryInfo?.postcode}{' '}
                        {bulkdeliveryInfo?.prefectureName}{' '}
                        {bulkdeliveryInfo?.city} {bulkdeliveryInfo?.address}
                      </div>
                    </li>
                  </ul>
                  <ul className="l-col_wrap l-flex c-label_line l-col_wrap__isSpMode">
                    <li className="l-col_12">
                      <ul className="l-flex">
                        <li className="c-dataLabel">宛名</li>
                        <li className="c-dataValue">
                          <span>{bulkdeliveryInfo?.addressee}</span>
                        </li>
                      </ul>
                    </li>
                    <li className="l-col_12">
                      <ul className="l-flex">
                        <li className="c-dataLabel">電話番号</li>
                        <li className="c-dataValue">
                          <span>{bulkdeliveryInfo?.telephonenumber}</span>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              )}
            </li>
          </ul>
        </li>
      </ul>
      <ul className="l-col_wrap l-flex c-label_line l-col_wrap__isSpMode">
        <li className="l-col_24">
          <ul className="l-flex">
            <li className="c-dataLabel p-groupsSummary_dataLabel_m">
              高解像度設定
            </li>
            <li className="c-dataValue">
              {societyContract.isHighResolution ? '有効' : '無効'}
            </li>
          </ul>
        </li>
      </ul>
      {/* 約款同意削除
      <ul className="l-col_wrap l-flex c-label_line l-col_wrap__isSpMode">
        <li className="l-col_24">
          <ul className="l-flex">
            <li className="c-dataLabel">約款同意日時</li>
            <li className="c-dataValue">
              <div className="t-bgBox_gray">
                {societyContractUseraccountAcceptedHistories!.map(
                  (history, index) => (
                    <ul
                      key={history.fiscalYear}
                      className={`l-col_wrap l-flex l-col_wrap__isSpMode${
                        index ? "" : " l-col_wrap__noBorder"
                      }`}
                    >
                      <li className="l-col_8">
                        <ul className="l-flex">
                          <li className="c-dataLabel">年度</li>
                          <li className="c-dataValue">
                            <span>{history.fiscalYear}</span>
                          </li>
                        </ul>
                      </li>
                      <li className="l-col_8">
                        <ul className="l-flex">
                          <li className="c-dataLabel">会員ID</li>
                          <li className="c-dataValue">
                            {history.useraccountno}
                          </li>
                        </ul>
                      </li>
                      <li className="l-col_8">
                        <ul className="l-flex">
                          <li className="c-dataLabel">同意日時</li>
                          <li className="c-dataValue">
                            <span>
                              {dateTimeFormat(
                                history.acceptedDay,
                                "同意していません"
                              )}
                            </span>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  )
                )}
              </div>
            </li>
          </ul>
        </li>
      </ul>
      */}
      <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
        <li className="l-col_24">
          <ul className="l-flex">
            <li className="c-dataLabel">
              千作業情報
              <span className="l-relative">
                <span className="c-tooltip u-mgl_xs">
                  <i className="c-icon_help"></i>
                </span>
                <span className="c-tooltip_body p-groupsSummary_tooltip_help">
                  千の団体の場合のみ表示されます
                </span>
              </span>
            </li>
            <li className="c-dataValue">
              <div className="t-bgBox_gray">
                <ul className="l-col_wrap l-flex l-col_wrap__isSpMode l-col_wrap__noBorder">
                  <li className="l-col_12">
                    <ul className="l-flex">
                      <li className="c-dataLabel p-groupsSummary_dataLabel_xl">
                        サンプルブックの有無(事前確認用)
                      </li>
                      <li className="c-dataValue">
                        {
                          societyDirectContract.isRequiredSamplebookForPreConfirmationName
                        }
                      </li>
                    </ul>
                  </li>
                  <li className="l-col_12">
                    <ul className="l-flex">
                      <li className="c-dataLabel p-groupsSummary_dataLabel_s">
                        FAX用紙の有無
                      </li>
                      <li className="c-dataValue">
                        {societyDirectContract.isRequiredFaxName}
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                  <li className="l-col_12">
                    <ul className="l-flex">
                      <li className="c-dataLabel">DVDの有無</li>
                      <li className="c-dataValue">
                        {societyDirectContract.isRequiredDvdName}
                      </li>
                    </ul>
                  </li>
                  <li className="l-col_12">
                    <ul className="l-flex">
                      <li className="c-dataLabel p-groupsSummary_dataLabel_s">
                        詳細FAX送信可否
                      </li>
                      <li className="c-dataValue">
                        {
                          societyDirectContract.isEnabledDetailedFaxSendingToGroupName
                        }
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                  <li className="l-col_12">
                    <ul className="l-flex">
                      <li className="c-dataLabel p-groupsSummary_dataLabel_m">
                        フォトマネー購入の可/不可
                      </li>
                      <li className="c-dataValue">
                        {societyDirectContract.isEnabledPointSalesName}
                      </li>
                    </ul>
                  </li>
                  <li className="l-col_4">
                    <ul className="l-flex">
                      <li className="c-dataLabel p-groupsSummary_dataLabel_s">
                        撮影確認書の自動作成
                      </li>
                      <li className="c-dataValue">
                        {societyDirectContract.isAutoCreateEventSchedulePdfName}
                      </li>
                    </ul>
                  </li>
                  <li className="l-col_6">
                    <ul className="l-flex">
                      <li className="c-dataLabel p-groupsSummary_dataLabel_m">
                        撮影詳細登録種別
                      </li>
                      <li className="c-dataValue">
                        {societyDirectContract.photographySubmitTypeName}
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                  <li className="l-col_24">
                    <ul className="l-flex">
                      <li className="c-dataLabel">撮影日程確認書</li>
                      <li className="c-dataValue">
                        <EventSchedulePdfUrl
                          url={societyContract.eventsSchedulePdfUrl}
                        />
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                  <li className="l-col_12">
                    <ul className="l-flex">
                      <li className="c-dataLabel">担当営業</li>
                      <li className="c-dataValue">
                        {societyDirectContract.salesDepartmentOwnerName}
                      </li>
                    </ul>
                  </li>
                  <li className="l-col_12">
                    <ul className="l-flex">
                      <li className="c-dataLabel">副担当営業</li>
                      <li className="c-dataValue">
                        {
                          societyDirectContract.secondarySalesDepartmentOwnerName
                        }
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                  <li className="l-col_24">
                    <ul className="l-flex">
                      <li className="c-dataLabel p-groupsSummary_dataLabel_m">
                        担当営業の変更履歴
                      </li>
                      <li className="c-dataValue">
                        <div className="u-pre_wrap">ZOHOに移行されました</div>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </li>
      </ul>
      <SalesManagement
        societyContractTypeId={societyContract.societyContractTypeId}
        salesManagement={salesManagement}
      />
      <ul className="l-col_wrap l-flex l-flex__isSpInput">
        <li className="l-col_24">
          <h4 className="c-section_title u-mgb_s u-mgt_s">契約プラン</h4>
          {societyContractPlans.length === 0 && '（登録されていません）'}
          {societyContractPlans.map((contractPlan, index) => (
            <ul key={`${societyContract.id}-${contractPlan.planId}`}>
              <li className="c-dataValue">
                <div className="t-bgBox_brown">
                  <div className="l-flex_between_center">
                    <h5
                      className="c-section_title tin u-mgb_s"
                      id={'p-groupsSummary_plan_' + (index + 1)}
                    >
                      NO.{index + 1}
                    </h5>
                    {societyContract.status === STATUS_CONTRACTING && (
                      <ul className="l-flex_end">
                        <li>
                          <Link
                            className="c-textlink"
                            to={`/events?societyContractIds[]=${societyContract.id}&planIds[]=${contractPlan.planId}`}
                          >
                            イベント一覧
                          </Link>
                        </li>
                        &nbsp;
                        {isContractTypeDirect(
                          societyContract.societyContractTypeId
                        ) && (
                          <>
                            <li>
                              <a
                                href={getEventRequestsCreateBySocietyContractPlanUrl(
                                  societyContract.id,
                                  contractPlan.planId
                                )}
                                className="c-textlink u-mgr_s"
                              >
                                イベント申請
                              </a>
                            </li>
                            <li>
                              <a
                                href={getEventRequestsCreateManyBySocietyContractPlanUrl(
                                  societyContract.id,
                                  contractPlan.planId
                                )}
                                className="c-textlink u-mgr_s"
                              >
                                イベント申請（過去イベントから複製）
                              </a>
                            </li>
                          </>
                        )}
                        <li>
                          <Link
                            className="c-textlink"
                            to={`/society_contracts/${societyContract.id}/plans/${contractPlan.planId}/events/create`}
                          >
                            イベント登録
                          </Link>
                        </li>
                      </ul>
                    )}
                  </div>
                  <div className="t-bgBox_white l-flex_between_center">
                    <ul className="l-col_wrap l-flex l-col_wrap__isSpMode l-col_wrap__noBorder">
                      <li className="l-col_12">
                        <ul className="l-flex">
                          <li className="c-dataLabel">プラン種類</li>
                          <li className="c-dataValue">
                            <span>{contractPlan.planName}</span>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_12">
                      <ul className="l-flex">
                        <li className="c-dataLabel">有効/無効</li>
                        <li className="c-dataValue">
                          <span>{contractPlan.isActiveName}</span>
                        </li>
                      </ul>
                    </li>
                    <li className="l-col_12">
                      <ul className="l-flex">
                        <li className="c-dataLabel p-groupsSummary_dataLabel_m">
                          文字入れ機能の有無
                        </li>
                        <li className="c-dataValue">
                          <span>{contractPlan.isEnabledOverlayTextName}</span>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_12">
                      <ul className="l-flex">
                        <li className="c-dataLabel p-groupsSummary_dataLabel_m">
                          期間外販売の有無
                        </li>
                        <li className="c-dataValue">
                          <span>
                            {contractPlan.isEnabledOutOfPeriodSalesName}
                          </span>
                        </li>
                      </ul>
                    </li>
                    <li className="l-col_12">
                      <ul className="l-flex">
                        <li className="c-dataLabel p-groupsSummary_dataLabel_m">
                          バリュー期間 or 掲載期間
                        </li>
                        <li className="c-dataValue">
                          <span>{contractPlan.publishtermName}</span>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_12">
                      <ul className="l-flex">
                        <li className="c-dataLabel p-groupsSummary_dataLabel_m">
                          閲覧専用かどうか
                        </li>
                        <li className="c-dataValue">
                          <span>{contractPlan.isViewonlyName}</span>
                        </li>
                      </ul>
                    </li>
                    <li className="l-col_12">
                      <ul className="l-flex">
                        <li className="c-dataLabel p-groupsSummary_dataLabel_m">
                          プリント会社
                        </li>
                        <li className="c-dataValue">
                          <span>{contractPlan.printingCompanyTypeName}</span>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_12">
                      <ul className="l-flex">
                        <li className="c-dataLabel p-groupsSummary_dataLabel_m">
                          データ小販売の有無
                        </li>
                        <li className="c-dataValue">
                          <span>{contractPlan.isEnabledSellDataSName}</span>
                        </li>
                      </ul>
                    </li>
                    <li className="l-col_12">
                      <ul className="l-flex">
                        <li className="c-dataLabel p-groupsSummary_dataLabel_m">
                          FAX販売
                        </li>
                        <li className="c-dataValue">
                          <span>{contractPlan.isEnabledSellByFaxName}</span>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_12">
                      <ul className="l-flex">
                        <li className="c-dataLabel">直販のインセンティブ率</li>
                        <li className="c-dataValue">
                          <span>{contractPlan.directSalesIncentiveRate}%</span>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <SalesManagementPlan
                    societyContractTypeId={
                      societyContract.societyContractTypeId
                    }
                    salesManagementPlan={salesManagementPlans.find(
                      (p) => p.planId === contractPlan.planId
                    )}
                  />
                  <DefaultPrice
                    societyContractId={societyContract.id}
                    planId={contractPlan.planId}
                    price={contractPlan.defaultPricePattern}
                  />
                </div>
              </li>
            </ul>
          ))}
        </li>
      </ul>
      {isContractTypeTeacher(societyContract.societyContractTypeId) && (
        <>
          <OwneracountList
            owneraccounts={owneraccounts}
            society_contract_id={societyContract.id}
            society_contract_status={societyContract.status}
            canCreateOwneraccount={canCreateOwneraccount}
          />
          <br />
        </>
      )}
    </div>
  );
};

const DefaultPrice: React.FC<{
  societyContractId: number;
  planId: number;
  price: TDefaultPricePattern | null;
}> = ({ societyContractId, planId, price }) => {
  return (
    <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
      <li className="l-col_24">
        <ul className="l-flex nowrap">
          <li className="c-dataLabel">基本価格</li>
          <li className="c-dataValue">
            <div>
              {price === null ? (
                <>価格は登録されていません</>
              ) : (
                <PriceTable {...price} showId={true} />
              )}
            </div>
            <Link
              to={`/society_contracts/${societyContractId}/plans/${planId}/prices`}
            >
              価格一覧・価格登録
            </Link>
          </li>
        </ul>
      </li>
    </ul>
  );
};

export default SocietyContractContainer;
