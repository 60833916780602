import { RAINY_DAY_ACTION_ID_NO_CANCEL } from '@/components/pages/Events/Show/Photography/const';
import { TPhotography } from '@/components/pages/Events/Show/Photography/types';

export const isMeetingPlaceDifferentBetweenSunnyAndRainy = (
  photography: TPhotography
): boolean => {
  if (photography?.rainyDayActionId !== RAINY_DAY_ACTION_ID_NO_CANCEL) {
    return false;
  }

  if (
    (photography?.meetingPlace === null ||
      photography?.meetingPlace === undefined) &&
    (photography?.rainyMeetingPlace === null ||
      photography?.rainyMeetingPlace === undefined ||
      photography?.rainyMeetingPlace === '')
  ) {
    return false;
  }

  // Note: 集合場所を指定できないイベントは園として判定する
  if (
    (photography?.meetingPlace === null ||
      photography?.meetingPlace === undefined) &&
    photography?.rainyMeetingPlace === '園'
  ) {
    return false;
  }

  if (/園以外/.test(photography?.meetingPlace ?? '')) {
    return (
      photography?.rainyMeetingPlace !== '園以外' ||
      (photography?.rainyMeetingPlace === '園以外' &&
        photography?.rainyMeetingPlaceMemo !== photography?.meetingPlaceMemo)
    );
  }

  return photography?.rainyMeetingPlace !== photography?.meetingPlace;
};
