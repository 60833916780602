import React from 'react';
import { useParams } from 'react-router-dom';
import { kanriUrl } from '../../../../ts/url';

const App: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  // ethnamの団体サマリにリダイレクトさせておく
  window.location.href = kanriUrl({ g: id });

  return <></>;
};

export default App;
