export type MailLog = {
  sendType: string;
  createdAt: string;
  email: string;
  event: Event;
  response?: string;
  reason?: string;
  status?: string;
  ip?: string;
};

export const event = [
  'processed',
  'dropped',
  'delivered',
  'deferred',
  'bounce',
  'open',
  'click',
  'spamreport',
] as const;

export const sendType = [
  'Cron_MailFreeEnd',
  'Cron_MailFreeMid',
  'Cron_MailFreePrevEnd',
  'Cron_MailFreeStart',
  'Cron_MailKeisaiEnd',
  'Cron_MailKeisaiMid',
  'Cron_MailKeisaiPrevEnd',
  'Cron_MailKeisaiStart',
  'Cron_MailValueEnd',
  'Cron_MailValueMid',
  'Cron_MailValuePrevEnd',
  'Cron_MailValueStart',
  'DaysAfterInsertCart',
  'pre_confirmation_request_remind',
];

export type Event = typeof event[number];
export type SendType = typeof sendType[number];

export const eventLabel: { [key in Event]: string } = {
  processed: '処理済み',
  dropped: '送信不可',
  delivered: '送信完了',
  deferred: '時的な送信失敗（リトライあり）',
  bounce: '送信失敗',
  open: '開封',
  click: 'URLクリック',
  spamreport: 'スパム',
};

export const sendTypeLabel: { [key in SendType]: string } = {
  Cron_MailFreeEnd: '閲覧専用またはフリーDLイベント終了当日メール',
  Cron_MailFreeMid: '閲覧専用またはフリーDLイベント終了5日前メール',
  Cron_MailFreePrevEnd: '閲覧専用またはフリーDLイベント終了前日メール',
  Cron_MailFreeStart: '閲覧専用またはフリーDLイベント開始メール',
  Cron_MailKeisaiEnd: '掲載終了当日メール',
  Cron_MailKeisaiMid: '掲載終了5日前メール',
  Cron_MailKeisaiPrevEnd: '掲載終了前日メール',
  Cron_MailKeisaiStart: '掲載開始メール',
  Cron_MailValueEnd: 'バリュー期間終了当日メール',
  Cron_MailValueMid: 'バリュー期間終了5日前メール',
  Cron_MailValuePrevEnd: 'バリュー期間終了前日メール',
  Cron_MailValueStart: 'バリュー期間開始メール',
  DaysAfterInsertCart: 'カートリマインドメール',
  pre_confirmation_request_remind: '事前確認リマインドメール',
};
